import Titlebanner from "../../../globel_cmponents/title_banner";
import FromSubmitToApi from "../../../utils/FromSubmitToApi";
import FormAddonForm from "./attractionForms/FormAddonForm";

const FormAdddOn = () => {
    return ( <>
    <Titlebanner title="Form Add On"/>
    <FormAddonForm/>
    </>);
}
 
export default FormAdddOn;