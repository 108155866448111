import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { reduxForm, Field } from "redux-form";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import RenderField from "../../formcomponent/formfields/RenderField";
import axios from "axios";
import requests from "../../../utils/Requests";
import RenderExcelUploadField from "../../formcomponent/ExcelUpload/RenderExcelUploadField";
import Swal from "sweetalert2";

const validate = (values) => {
  const errors = {};
  if (!values.attractionsId) {
    errors.attractionsId = "Required";
  }
  if (!values.ticketTypeId) {
    errors.ticketTypeId = "Requird";
  }
  if (!values.adultOrChild) {
    errors.adultOrChild = "Required";
  }
  if (!values.ticketExpiry) {
    errors.ticketExpiry = "Required";
  }
  if (!values.bookNumber) {
    errors.bookNumber = "Required";
  }
  return errors;
};

const GenerateTempTicket = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  const [parkGroup, setparkGroup] = useState([{ value: "", name: "" }]);
  const [attractionList, setattractionList] = useState([
    { name: "", value: "" },
  ]);
  const [adultOrChild, setadultOrChild] = useState([
    { name: "", value: "" },
    { name: "ADULT", value: "ADULT" },
    { name: "CHILD", value: "CHILD" },
  ]);
  useEffect(() => {
    getallparkgroup();
  }, []);

  const getallparkgroup = async () => {
    await axios
      .post(requests.getallparkgroup, { attractionsId: 1 })
      .then((res) => {
        // console.log(res.data)

        const values = [...parkGroup];
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].grpName,
            value: res.data[i].attractionGroupId,
          });
          // console.log(values);
        }
        setparkGroup(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getattractionbygroup = async (groupId) => {
    await axios
      .post(requests.getattractionallbygroup, { attGroup: groupId })
      .then((res) => {
        const values = [...attractionList];
        values.length = 0;
        values.push({
          name: "Select Attraction",
          value: "",
        });
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].attName,
            value: res.data[i].attractionsId,
          });
        }
        setattractionList(values);
      })
      .catch((err) => {});
  };
  const [tkttypeTemp, settkttypeTemp] = useState([]);

  const apicalltkt = (attId) => {
    axios
      .post(requests.getTicketTypeListByAttraction, { ttAttractionId: attId })
      .then((res) => {
        console.log("res tickets", res.data);
        const values = [...tkttypeTemp];
        values.length = 0;
        values.push({
          name: "Select Ticket Type",
          value: "",
        });

        for (let i = 0; i < res.data.length; i++) {
          values.push({
            name: res.data[i].ttTicketType,
            value: res.data[i].ticketTypeId,
          });
        }
        settkttypeTemp(values);
      })
      .catch((err) => {});
  };

  const ticketGenerate = async (values) => {
    console.log("values", values);
    Swal.fire("Wait", "Please wait your data is processing", "info");
    await axios
      .post(requests.generateTicket, values)
      .then((res) => {
        Swal.fire({
          title: "Tickets",
          text: "Your Tickets are generated ",
          icon: "success",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Download Tickets",
        }).then((result) => {
          if (result.isConfirmed) {
            const fileURL =
              // "http://66.29.149.191:8080/filestorage/parmartour/images/" +
              "https://b2b.parmartours.com/filestorage/" +
              res.data.bookingTickPdfPath;
            const filename = "e-Ticket.pdf";
            download_file(fileURL, filename);
          }
        });
        console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function download_file(fileURL, fileName) {
    // for non-IE

    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  }

  return (
    <div>
      <Titlebanner title="Ticket Generation" />
      <Card>
        <CardBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="exampleSelect">Select Attraction Group</Label>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => getattractionbygroup(e.target.value)}
                >
                  {parkGroup.map((attration, index) => (
                    <option value={attration.value}> {attration.name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <Field
                name="attractionsId"
                type="select"
                customfeild={attractionList}
                component={RenderField.renderOptionField}
                id="ttAttractionId"
                onChange={(e) => apicalltkt(e.target.value)}
                label="Select Attraction "
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name="ticketTypeId"
                type="select"
                customfeild={tkttypeTemp}
                component={RenderField.renderOptionField}
                label="Ticket Type "
                // onChange={(e) =>
                //   initialval( e.target.value)
                // }
              />{" "}
            </Col>
            <Col>
              <Field
                name="adultOrChild"
                type="select"
                customfeild={adultOrChild}
                component={RenderField.renderOptionField}
                label="Adult Or Child "
                // onChange={(e) =>
                //   initialval( e.target.value)
                // }
              />{" "}
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                name="ticketExpiry"
                type="date"
                component={RenderField.RenderTextField}
                label="Ticket Expiry "
                // onChange={(e) =>
                //   initialval( e.target.value)
                // }
              />{" "}
            </Col>
            <Col>
              <Field
                name="bookNumber"
                type="text"
                component={RenderField.RenderTextField}
                label="Book Number"
                // onChange={(e) =>
                //   initialval( e.target.value)
                // }
              />{" "}
            </Col>
          </Row>
          <Row>
            <Field name="ticketNumber" component={RenderExcelUploadField} />
          </Row>

          <div className="float-right">
            <Button
              color="primary"
              onClick={handleSubmit((values) =>
                ticketGenerate({
                  ...values,
                  pill: 1,
                })
              )}
            >
              Generate Ticket
            </Button>
            &nbsp; &nbsp;&nbsp;
            <Button
              color="danger"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Cancel
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default reduxForm({
  form: "GenerateTempTicket",
  validate,
})(GenerateTempTicket);
