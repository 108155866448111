import Titlebanner from "../../../globel_cmponents/title_banner";
import { Link } from "react-router-dom";
import RenderField from "../../formcomponent/formfields/RenderField";

import Select from "react-select";
import {
  Button,
  Row,
  Col,
  Label,
  Spinner,
  Alert,
  Input,
  FormGroup,
} from "reactstrap";
import { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import requests from "../../../utils/Requests";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import { Field, Form, reduxForm } from "redux-form";
const Paymentdetails = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  useEffect(() => {
    getagency();
  }, []);

  let attraction = {
    attractionsId: 1,
    secretKey: requests.apiKey,
    platformId: 1,
  };
  const [agencyList, setagencyList] = useState([]);

  const getagency = () => {
    axios
      .post(requests.getagencylist, attraction)
      .then((res) => {
        let values = [...agencyList];
        values.length = 0;
        values.push({
          label: "Select Agent",
          value: 0,
        });
        for (let i = 0; i < res.data.length; i++) {
          values.push({
            label: res.data[i].agencyName,
            value: res.data[i].agencyId,
          });
        }
        setagencyList(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [tourlist, setTourlist] = useState([]);

  const [attractionList, setattractionList] = useState([
    { name: "", value: "" },
  ]);
  const [isLoading, setisLoading] = useState(0);

  // const tempProducts = productList.map(
  //   ({
  //     productName,
  //     distributorId,
  //     productDesc,
  //     price,
  //     productId,
  //     imageUrl
  //   }) => ({
  //     name: productName,
  //     description: productDesc,
  //     distId: distributorId,
  //     price: price,
  //     productId: productId,
  //     count: 0,
  //     poster: 'http://103.235.106.127:8080/images/h2ocloud/product/'+imageUrl,
  //   })

  // );

  // const SoldOrAvilable = (tktBookingId) => {
  //   if (tktBookingId == 0) {
  //     return "Available";
  //   } else {
  //     return "Sold";
  //   }
  // };

  const [rowData, setrowData] = useState([""]);
  const [gridApi, setGridApi] = useState(null);
  // const setFillter = (attid) => {
  //   // console.log(tourlist[1].attractionId);
  //   // console.log(attid);
  //   // console.log([1].attractionId);
  //   setisLoading(1);
  //   const tktfilter = attractionList.filter((tour) => tour.value == attid);

  //   console.log(tktfilter[0].name);
  //   getdata(tktfilter[0].name, tktfilter[0].value);
  // };

  const [agentOrDateVise, setagentOrDateVise] = useState("1");
  const [agentId, setagentId] = useState();
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const getPayDetails = (values) => {
    console.log({
      agencyId: agentId,
      startDate: startDate,
      endDate: endDate,
      filterType: agentOrDateVise,
      secretKey: requests.apiKey,
    });
    axios
      .post(requests.getTopupList, {
        platformId: 1,
        agencyId: agentId,
        startDate: startDate,
        endDate: endDate,
        filterType: agentOrDateVise,
        secretKey: requests.apiKey,
      })
      .then((respoce) => {
        //  setrowData(respoce.data);
        setRecord(respoce.data);

        console.log(respoce.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setRecord = (tempData) => {
    const tempRecord = tempData.map(
      ({
        agentName,
        tranCreditLimit,
        trnDate,
        paymentType,
        trnRemarks,
        tranCreditAmount,
        tranCurrentBanlance,
      }) => ({
        agentName: agentName,
        tranCreditLimit: tranCreditLimit,
        trnDate: trnDate,
        paymentType: paymentType,
        trnRemarks: trnRemarks,
        tranCreditAmount: tranCreditAmount,
        tranCurrentBanlance: tranCurrentBanlance,
      })
    );
    setrowData(tempRecord);
  };

  let columnDefs = [
    { headerName: "Credit Limit", field: "tranCreditLimit" },
    { headerName: "Updated On", field: "trnDate" },
    { headerName: "Payment Type", field: "paymentType" },
    { headerName: "Remark", field: "trnRemarks" },

    { headerName: "Credited Amount", field: "tranCreditAmount" },
    { headerName: "Current Balance", field: "tranCurrentBanlance" },
  ];
  if (agentOrDateVise === "1") {
    columnDefs = [
      { headerName: "Agent Name", field: "agentName" },
      ...columnDefs, // Add other columns
    ];
  }

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    minWidth: 100,
  };
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <Titlebanner title="Payment Details" />
      <div className="float-right">
        <Link
          to="/Payment/PaymentCreation/payment-add-payment-creation"
          class="btn btn-primary"
          role="button"
        >
          Create New
        </Link>
      </div>

      <br></br>

      <div className="App">
        <Row>
          <Col>
            <label>
              <input
                name="attCodeFormat"
                component="input"
                type="radio"
                checked={agentOrDateVise == 1 ? "checked" : null}
                value="1"
                onChange={(e) => setagentOrDateVise(e.target.value)}
              />{" "}
              Date Filter
            </label>
          </Col>
          <Col>
            <label>
              <input
                name="attCodeFormat"
                component="input"
                type="radio"
                checked={agentOrDateVise == 2 ? "checked" : null}
                value="2"
                onChange={(e) => setagentOrDateVise(e.target.value)}
              />{" "}
              Agent List
            </label>
          </Col>
        </Row>
        {agentOrDateVise == 2 ? (
          <FormGroup>
            <Row>
              <Col sm={3}>
                <Label for="exampleSelect">Select Agent</Label>

                <Select
                  // value={selectedOption}
                  onChange={(e) => setagentId(e.value)}
                  options={agencyList}
                />
              </Col>
              <Col sm={3}>
                <div
                  style={{
                    marginTop: "28px",
                  }}
                >
                  <Button color="primary" onClick={(e) => getPayDetails()}>
                    Get List
                  </Button>
                </div>
              </Col>
            </Row>
          </FormGroup>
        ) : (
          <Row>
            <Col sm={3}>
              <Field
                name="startDate"
                type="date"
                label="Start Date"
                onChange={(e) => setstartDate(e.target.value)}
                component={RenderField.RenderTextField}
              />
            </Col>
            <Col sm={3}>
              <Field
                name="endDate"
                type="date"
                label="End Date"
                onChange={(e) => setendDate(e.target.value)}
                component={RenderField.RenderTextField}
              />
            </Col>
            <Col sm={3}>
              <div
                style={{
                  marginTop: "28px",
                }}
              >
                <Button color="primary" onClick={(e) => getPayDetails()}>
                  Get List
                </Button>
              </div>
            </Col>
          </Row>
        )}

        {isLoading == 1 ? (
          <>
            {" "}
            <Alert color="primary">
              Your Data Is Loading Please Wait <Spinner color="light" />
            </Alert>
          </>
        ) : null}

        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <div>
            {rowData != "" && (
              <Button
                color="primary"
                onClick={(e) => exportToCSV(rowData, "Payment_Report")}
              >
                Download CSV
              </Button>
            )}
          </div>
          <AgGridReact
            id="Inventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            // onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            // paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </>
  );
};

export default reduxForm({
  form: "Paymentdetails",
})(Paymentdetails);
