import axios from "axios";
import { Spinner, Button, Row, Col } from "reactstrap";
import { Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import requests from "../../../utils/Requests";
import Titlebanner from "../../../globel_cmponents/title_banner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const TicketAvailability = () => {
  const [isLoading, setisLoading] = useState(true);
  const [rowData, setrowData] = useState([]);
  useEffect(() => {
    getTicketInventoryReport();
  }, []);

  const getTicketInventoryReport = async () => {
    await axios
      .post(requests.getTicketListAvailability, { attractionId: 0 })
      .then((res) => {
        console.log(res.data);

        const dataWithId = res.data.map((item, index) => ({
          ...item,
          id: index,
          export: true,
        }));

        setrowData(res.data);
        setisLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // if (isLoading) {
  //   return <Spinner />;
  // }

  const columnDefs = [
    { headerName: "Attraction Name", field: "attractionName" },
    { headerName: "Ticket Type", field: "ticketType" },
    { headerName: "Adult(Available)", field: "availableAdultCount" },
    { headerName: "Child(Available)", field: "availableChildCount" },
  ];
  const defaultColDef = {
    sortable: true,
    // editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  const handleCheckboxChange = (rowDataItem) => {
    // Clone the array to avoid modifying the state directly
    const updatedRowData = [...rowData];

    // Find the index of the item to update
    const indexToUpdate = updatedRowData.findIndex(
      (row) => row.ticketTypeId === rowDataItem.ticketTypeId
    );

    // If the item is found, update it
    if (indexToUpdate !== -1) {
      // Clone the specific object to avoid modifying the state directly
      const updatedRow = { ...updatedRowData[indexToUpdate] };

      // Update the property
      updatedRow.showInReport = !updatedRow.showInReport;

      // Update the array with the modified object
      updatedRowData[indexToUpdate] = updatedRow;

      // Update the state with the modified array

      // Perform the axios request with the modified object

      console.log(updatedRow);
      axios
        .post(requests.updateTicketTypeShowInReport, updatedRow)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setrowData(updatedRowData);
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData, fileName) => {
    const filteredData = csvData.filter((item) => item.showInReport);

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const [search, setsearch] = useState("");
  const filterData = rowData.filter((data) => {
    return data.attractionName
      ? data.attractionName.toLowerCase().includes(search.toLocaleLowerCase())
      : "".toLowerCase().includes(search.toLocaleLowerCase());
  });
  return (
    <div>
      <Titlebanner title="Ticket Availability" />
      {isLoading ? (
        <div>
          <div className={"d-flex justify-content-center align-items-center"}>
            <Spinner /> &nbsp; &nbsp; Loading Please wait it will take some time
          </div>
        </div>
      ) : (
        <div className="ag-theme-alpine">
          <div>
            <Button
              color="primary"
              onClick={(e) => exportToCSV(rowData, "Ticket_Stock")}
              style={{ marginBottom: "5px", fontWeight: "bold" }}
            >
              Export to Excel
            </Button>
          </div>
          {/* <AgGridReact
            id="TicketInventory"
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            pagination={true}
          /> */}
          <br />
          <Row>
            <Col>
              {" "}
              <input
                type="text"
                placeholder="Search Attraction"
                onChange={(e) => setsearch(e.target.value)}
              />{" "}
            </Col>
            <Col></Col>
            <Col> </Col>
            <Col> </Col>
            <Col></Col>
            <br />
          </Row>
          <br />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Attraction Name</th>
                <th>Ticket</th>
                <th>Adult(Available)</th>
                <th>Child(Available)</th>
                <th>Export</th>
              </tr>
            </thead>

            <tbody>
              {filterData.map((item, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "even-row" : "odd-row"}
                >
                  <td>{item.attractionName}</td>
                  <td>{item.ticketType}</td>
                  <td>{item.availableAdultCount}</td>
                  <td>{item.availableChildCount}</td>
                  <td>
                    <input
                      type="checkbox"
                      id={`exportCheckbox_${item.id}`}
                      checked={item.showInReport}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default TicketAvailability;
