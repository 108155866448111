import { useEffect } from "react";
import { Field } from "redux-form";
import { Button} from "reactstrap";
const InclutionExclution = (props) => {
const {fields} = props;

  const renderSubFields = (Ticket, index, fields) => (
    <div key={index}>

        
        
     


   
    </div>
  );
return <></>;
};

export default InclutionExclution;
{/* https://stackoverflow.com/questions/42368174/redux-form-pass-value-to-fieldarray-fields */}