import React from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import BannerImageUpload from "./forms/BannerImageUpload";
const BannerImages = () => {
  return (
    <>
      <Titlebanner title="Website Banner Images" />
      <BannerImageUpload />
    </>
  );
};

export default BannerImages;
