import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import requests from "../../../utils/Requests";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import { Button } from "reactstrap";

const HotelEnquiryList = () => {
  const history = useHistory();
  useEffect(() => {
    getTransportList();
  }, []);
  const [rowData, setrowData] = useState([]);

  const getTransportList = async () => {
    try {
      const hotelListResponse = await axios.post(requests.getHotelList, {
        hotelId: 1,
      });
      const hotelListData = hotelListResponse.data;

      const response = await axios.post(requests.getHotelBookingList, {
        hotelBookingId: 1,
      });

      const modifiedData = response.data.map((item) => ({
        ...item,
        hotelName: hotelListData.find((hotel) => hotel.hotelId === item.hotelId)
          ?.hotelName,
      }));
      console.log(modifiedData);
      setrowData(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  const columnDefs = [
    { headerName: "Hotel Name", field: "hotelName" },
    { headerName: "Check In", field: "checkInDate" },
    { headerName: "Check Out", field: "checkOutDate" },

    { headerName: "Adult", field: "nofAdult" },
    { headerName: "Child", field: "nofChild" },
    { headerName: "Guest Name", field: "custName" },
    { headerName: "Contact", field: "custPhoneNumber" },
    { headerName: "Email", field: "custEmailId" },
    { headerName: "Book Date", field: "bookingDate" },
  ];

  const actionButtonEdit = (params) => {
    history.push(
      `/Trasnport/ListTransport/transport-create-transport/${params.data.transferId}`
    );
  };

  const actionButton = async (params) => {
    await axios
      .post(requests.deleteAttractionTransfer, {
        transferId: params.data.transferId,
      })
      .then((res) => {
        getTransportList(params.data.transferId);
        console.log(res.data);
        Swal.fire("Deleted", "Attraction Transfer Deleted", "success");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div>
      <Titlebanner title="Hotel Enquiry List" />

      <br />
      <br />
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            //onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default HotelEnquiryList;
