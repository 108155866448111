/** @format */

import React, { useEffect, useState } from "react";
import { reduxForm, Field, Form } from "redux-form";
import RenderField from "../../../formcomponent/formfields/RenderField";
import { Row, Col, Card, CardBody, Button, Table } from "reactstrap";
import axios from "axios";
import requests from "../../../../utils/Requests";
import Titlebanner from "../../../../globel_cmponents/title_banner";
import { useHistory, useParams } from "react-router";
import Swal from "sweetalert2";
const validate = (values) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = "Required";
  }
  if (!values.userEmail) {
    errors.userEmail = "Required";
  }
  if (!values.userType) {
    errors.userType = "Required";
  }
  if (!values.userPassword1) {
    errors.userPassword1 = "Required";
  } else if (values.userPassword1.length < 8) {
    errors.userPassword1 = "Minimum be 8 characters or more";
  } else if (!/[A-Z]/.test(values.userPassword1)) {
    errors.userPassword1 = "Minimum one upper Case";
  } else if (
    !/[\d`~!@#$%\^&*()+=|;:'",.<>\/?\\\-]/.test(values.userPassword1)
  ) {
    errors.userPassword1 = "Minimum one special character";
  }

  if (!values.userPassword) {
    errors.userPassword = "Required";
  }
  if (values.userPassword != values.userPassword1) {
    errors.userPassword = "Not Match";
  }
  if (!values.accountStatus) {
    errors.accountStatus = "Required";
  }

  return errors;
};
const PassengerType = (props) => {
  const { handleSubmit, pristine, reset, submitting } = props;
  let history = useHistory();
  const userGroup = [
    { value: "", name: "Select" },
    { value: 1, name: "Super Admin" },
    { value: 2, name: "Company Creation" },
    { value: 3, name: "Back Office Admin" },
    { value: 4, name: "IT Team" },
  ];

  const statusOption = [
    { value: "", name: "Select" },
    { value: "active", name: "Active" },
    { value: "inactive", name: "InActive" },
  ];

  let { id } = useParams();
  useEffect(() => {
    getUserDataList();
  }, [id]);

  const getUserDataList = () => {
    axios
      .post(requests.getUserDataList, { userListId: 0, platformId: 1 })
      .then((res) => {
        const filterData = res.data.find((item) => item.userListId == id);

        console.log(JSON.stringify(filterData, null, 2));
        props.initialize({
          ...filterData,
          userPassword1: filterData?.userPassword,
        }); //userPassword
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = (values) => {
    // if(values.pilll == 2){

    // }else{

    // }
    const submitData = {
      userName: values.userName,
      userEmail: values.userEmail,
      userPassword: values.userPassword,
      userType: values.userType,
      accountStatus: values.accountStatus,
    };

    console.log(values);

    axios
      .post(requests.setUserData, values)
      .then((response) => {
        console.log(response.data);
        if (response.status == 200) {
          Swal.fire({
            title: "success!", //'Good job!',
            text: "New User Created Successfully", //'You clicked the button.',
            icon: "success", //'success'
          });
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <Titlebanner title="Sub-User Details" />
      <Card>
        <CardBody>
          <Form>
            <Row>
              <Col>
                {" "}
                <Field
                  name="userName"
                  type="text"
                  label="Name"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                {" "}
                <Field
                  name="userEmail"
                  type="email"
                  label="Email"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="userType"
                  type="select"
                  customfeild={userGroup}
                  label="User Type"
                  component={RenderField.renderOptionField}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="userPassword1"
                  type="password"
                  label="Password"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="userPassword"
                  type="password"
                  label="Confirm Password"
                  component={RenderField.RenderTextField}
                />
              </Col>
              <Col>
                <Field
                  name="accountStatus"
                  type="select"
                  customfeild={statusOption}
                  label="Account Status"
                  component={RenderField.renderOptionField}
                />
              </Col>
            </Row>
            <div className="float-right">
              <Button
                color="primary"
                className="btn btn-yellow"
                onClick={handleSubmit((values) =>
                  onSubmit({
                    ...values,
                    platformId: 1,
                    pill: 1,
                  })
                )}
              >
                Save
              </Button>
              &nbsp; &nbsp;&nbsp;
              <Button
                color="danger"
                onClick={reset}
                disabled={submitting || pristine}
              >
                Cancel
              </Button>{" "}
            </div>
          </Form>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div>
            <Field
              name="dashboard"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Dashboard"
            />
          </div>
          <div>
            <Field
              name="websiteSettings"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Website Settings"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="bannerImages"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Banner Images"
              />
              <Field
                name="languageSettings"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Language Settings"
              />
            </div>
          </div>
          <div>
            <Field
              name="admin"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Admin"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="roles"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Roles"
              />
              <Field
                name="roleProgramLink"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Role ProgramLink"
              />
              <Field
                name="customerType"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Customer Type"
              />
              <Field
                name="ticketType"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Ticket Type"
              />
              <Field
                name="subUserCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Sub User Creation"
              />
              <Field
                name="agencyCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Agency Creation"
              />
              <Field
                name="generatePDF"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Generate PDF"
              />
            </div>
          </div>
          <div>
            <Field
              name="payment"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Payment"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="paymentCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Payment Creation"
              />
              <Field
                name="onlinePayment"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Online Payment"
              />

              <Field
                name="markupDiscount"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Markup Discount"
              />
            </div>
          </div>

          <div>
            <Field
              name="apisettings"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="API Settings"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="apimaster"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="API Master"
              />
              <Field
                name="priceSettings"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Price Settings"
              />
            </div>
          </div>

          <div>
            <Field
              name="agentAPISettings"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Agent API Settings"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="createAccesspermission"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Create Access permission"
              />
              <Field
                name="uploadTestDummyTicket"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Upload Test Dummy Ticket"
              />
            </div>
          </div>
          <div>
            <Field
              name="attraction"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Attraction"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="attractionCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="AttractionCreation"
              />
              <Field
                name="attractionList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="AttractionList"
              />
              <Field
                name="setHolidays"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Set Holidays"
              />
              <Field
                name="ticketCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Ticket Creation"
              />
              <Field
                name="ticketGeneration"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Ticket Generation"
              />
              <Field
                name="ticketList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="TicketList"
              />
              <Field
                name="comboPackCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Combo Pack Creation"
              />
              <Field
                name="comboPacklist"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Combo Packlist"
              />
              <Field
                name="parkGrouping"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Park Grouping"
              />
              <Field
                name="bannerAddOn"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="BannerAddOn"
              />
              <Field
                name="formAddOn"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="FormAddOn"
              />
              <Field
                name="addOnBarcode"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="AddOnBarcode"
              />
            </div>
          </div>

          <div>
            <Field
              name="tourPack"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="TourPack"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="tourPackGroup"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Tour PackGroup"
              />
              <Field
                name="tourPackCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Tour PackCreation"
              />
              <Field
                name="tourPackList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Tour PackList"
              />
            </div>
          </div>

          <div>
            <Field
              name="hotel"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Hotel"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="hotelAminities"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Hotel Aminities"
              />
              <Field
                name="listHotel"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="List Hotel"
              />
              <Field
                name="createHotel"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="CreateHotel"
              />
              <Field
                name="hotelEnquiryList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Hotel Enquiry List"
              />
            </div>
          </div>

          <div>
            <Field
              name="trasnport"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Trasnport"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="attractionTransport"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="AttractionTransport"
              />
              <Field
                name="listTransport"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="ListTransport"
              />
              <Field
                name="transportBookedList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Transport BookedList"
              />
            </div>
          </div>

          <div>
            <Field
              name="visa"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Visa"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="visaCreation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Visa Creation"
              />
              <Field
                name="visaList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Visa List"
              />
            </div>
          </div>

          <div>
            <Field
              name="booking"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Booking"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="cancellation"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Cancellation"
              />
              <Field
                name="returnTickets"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="ReturnTickets"
              />
            </div>
          </div>

          <div>
            <Field
              name="reviewAndFaq"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="ReviewAndFaq"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="review"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Review"
              />
              <Field
                name="faq"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="FAQ"
              />
            </div>
          </div>

          <div>
            <Field
              name="reports"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Reports"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="dailySales"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Daily Sales"
              />
              <Field
                name="b2CSales"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="B2C Sales"
              />
              <Field
                name="monthlySales"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Monthly Sales"
              />
              <Field
                name="apiticket"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="API Ticket"
              />
              <Field
                name="bookingList"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Booking List"
              />
              <Field
                name="attractionWithTransfer"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Attraction WithTransfer"
              />
              <Field
                name="addonStock"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Addon Stock"
              />
              <Field
                name="agentSalesReport"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Agent SalesReport"
              />
              <Field
                name="stockSummery"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Stock Summery"
              />
              <Field
                name="inventory"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Inventory"
              />

              <Field
                name="ticketAvailability"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Ticket Availability"
              />
            </div>
          </div>

          <div>
            <Field
              name="enquiryList"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Enquiry List"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="tourEnquiry"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Tour Enquiry"
              />
              <Field
                name="visaEnquiry"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Visa Enquiry"
              />
              <Field
                name="commonEnquiry"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Common Enquiry"
              />
            </div>
          </div>

          <div>
            <Field
              name="tourVisionaryMarkups"
              type="checkbox"
              component={RenderField.RendercheckboxField}
              label="Tour Visionary Markups"
            />
            <div
              style={{
                marginLeft: "30px",
              }}
            >
              <Field
                name="createAPIMarkup"
                type="checkbox"
                component={RenderField.RendercheckboxField}
                label="Create API Markup"
              />
            </div>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Table borderless>
            <thead>
              <tr>
                <th>#</th>
                <th>Super Admin</th>
                <th>Company Creation</th>
                <th>Back Office Admin</th>
                <th>IT Team</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Access Types</th>
                <td>All Parmissions</td>
                <td>Only Agency Creation</td>
                <td>Ticket Creation and All Reports</td>
                <td>All Parmissions(Except Agent Top-up)</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default reduxForm({
  form: "PassengerType",
  validate,
})(PassengerType);
