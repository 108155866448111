
const CommonEnq = () => {
    return ( <>
       <div className='small-box bg-warning'>
          <div className='inner'>
            <h3>0</h3>
            <p>Common Enquiry</p>
          </div>
          <div className='icon'>
            {/* <i className='ion ion-bag' /> */}
          </div>
          {/* <a href='#' className='small-box-footer'>
            More info <i className='fas fa-arrow-circle-right' />
          </a> */}
        </div>
    </> );
}
 
export default CommonEnq;