import React, { useEffect, useState } from "react";
import Titlebanner from "../../../globel_cmponents/title_banner";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import requests from "../../../utils/Requests";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
const TransportBookingList = () => {
  const [rowData, setrowData] = useState([]);
  useEffect(() => {
    getTransportList();
  }, []);
  const getTransportList = () => {
    axios
      .post(requests.getPrivateTransferList, { transferId: 1 })
      .then((res) => {
        console.log(res.data);
        setrowData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columnDefs = [
    { headerName: "Name", field: "custName" },
    { headerName: "Email", field: "custEmailId" },
    { headerName: "Pickup ", field: "pickupLocation" },
    { headerName: "Travel Date", field: "pickupDate" },
    { headerName: "Pickup Time", field: "pickupTime" },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
  };
  return (
    <div>
      <Titlebanner title="Transport Booking List" />
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "500px" }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            defaultColDef={defaultColDef}
            //onGridReady={onGridReady}
            pagination={true}
            // paginationPageSize={5}
            paginationAutoPageSize={true}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default TransportBookingList;
